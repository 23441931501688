export const useBookingStore = defineStore(
    "booking",
    () => {
      const order = reactive({
        gclid: null,
        payment_id: null,
        park_title: null,
        park_data: [],
        date: [],
        carReg: null,
        details: {
          email: null,
          guestId: null,
          terms_21: false,
          terms_vehicles: false,
          terms_subscribe: false,
        },
        registered: false,
        selected: [],
        selected_result: null,
        selected_nights_count: 0,
        selected_guests_count: 0,
        extras: [],
        extras_total: 0,
        selected_payment_option: {
          amount: 0,
          method: null,
        },
        pets: 0,
        selected_tarrifId: null,
        product_meta: null,
        guest_session: null,
      });
  
      const reset = () => {
        order.ProvisionalBookings = {}
        order.gclid = null;
        order.payment_id = null;
        order.park_title = null;
        order.park_data = [];
        order.date = [];
        order.carReg = null;
        order.order_total = null;
        order.details = {
            email: null,
            guestId: null,
            terms_21: false,
            terms_vehicles: false,
            terms_subscribe: false,
        }; 
        order.registered = false;
        order.selected = [];
        order.selected_result = null;
        order.selected_nights_count = 0;
        order.selected_guests_count = 0;
        order.extras = [];
        order.extras_total = 0;
        order.selected_payment_option = {
          amount: 0,
          method: null,
        };
        order.pets = 0;
        order.selected_tarrifId = null;
        order.product_meta = null;
        order.session_start = null;
        order.wp_id = null;
      };
      
  
      return {
        order,
        reset,
      };
    },
    {
      persist: {
        storage: persistedState.localStorage,
      },
    }
  );
  